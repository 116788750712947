
import React, { Component } from 'react'; //different
//import './style.css';
import NavMenu from './navmenu';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var settings = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};

class App extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <>
                <section className="-contact-wrapper row m-0">
                    <div className="-games-provider-wrapper pl-0 pr-0 mx-auto -menu-index-page-bg col-12">
                        {/**/}
                    </div>
                    <div className="-games-index-page col-12">
                        <div className="mb-2 text-white-v1">
                            <div className="d-none d-lg-block">
                                <div>
                                    <br />
                                    <br />
                                    <br />
                                </div>
                                {/**/}
                                {/**/}
                                <div>{/**/}</div>
                                {/**/}
                                {/**/}
                            </div>
                            {/**/}
                            <div className="padding-router-view">
                                <div className="container p-0 x-margin-top-v3">
                                    <div className="row m-0 border-shadow-title">
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                            <div className="text-center bg-title-web">
                                                <div>
                                                    <div className="text-title text-white-v1 text-center">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="text-white-v1-deposit">
                                                                <a role="button"
                                                                    href='/'>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={25}
                                                                        height={25}
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-left-circle text-color-back"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <div className="text-white-v1-deposit">
                                                                <h5>กิจกรรม</h5>
                                                            </div>
                                                            <div>{/**/}</div>
                                                        </div>
                                                        <hr className="mt-0" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="bg-event-box animated animatedFadeInUp fadeInUp"
                                                data-v-8a7c0ea5=""
                                            >
                                                <div className="banner-event" data-v-8a7c0ea5="">
                                                    <div className="px-0 card-width-banner" data-v-8a7c0ea5="">
                                                        <div
                                                            className="flicking-viewport"
                                                            data-v-8a7c0ea5=""
                                                            style={{
                                                                userSelect: "none",
                                                                WebkitUserDrag: "none",
                                                                touchAction: "pan-y"
                                                            }}
                                                        >
                                                            <Slider {...settings}>
                                                                <div
                                                                    className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                                                    data-slick-index={-2}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}

                                                                >
                                                                    <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                                                        <picture>
                                                                            <img
                                                                                className="img-fluid -slick-item -item-9"
                                                                                src="/build/web/igame-index-lobby-wm/img/ram-คืนค่าคอม.jpg"
                                                                                alt="First slide"
                                                                                width={1200}
                                                                                height={590}
                                                                                style={{ borderRadius: "0.5rem !important" }}
                                                                            />
                                                                        </picture>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                                                    data-slick-index={-1}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}

                                                                >
                                                                    <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                                                        <picture>
                                                                            <img
                                                                                className="img-fluid -slick-item -item-9"
                                                                                src="/build/web/igame-index-lobby-wm/img/ram-คืนยอดเสีย.jpg"
                                                                                alt="First slide"
                                                                                width={1200}
                                                                                height={590}
                                                                                style={{ borderRadius: "0.5rem !important" }}
                                                                            />
                                                                        </picture>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                                                    data-slick-index={-1}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                >
                                                                    <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                                                        <picture>
                                                                            <img
                                                                                className="img-fluid -slick-item -item-9"
                                                                                src="/build/web/igame-index-lobby-wm/img/ram-แนะนำเพื่อน.jpg"
                                                                                alt="First slide"
                                                                                width={1200}
                                                                                height={590}
                                                                                style={{ borderRadius: "0.5rem !important" }}
                                                                            />
                                                                        </picture>
                                                                    </div>
                                                                </div>
                                                            </Slider >
                                                            <div
                                                                className="flicking-pagination flicking-pagination-bullets"
                                                                data-v-8a7c0ea5=""
                                                            >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row m-1" data-v-8a7c0ea5="">
                                                    <div className="col-6 p-0" data-v-8a7c0ea5="">
                                                        <div className="border-box-credit" data-v-8a7c0ea5="">
                                                            <div
                                                                className="d-flex justify-content-between"
                                                                data-v-8a7c0ea5=""
                                                            >
                                                                <div className="icon" data-v-8a7c0ea5="">
                                                                    <div data-v-8a7c0ea5="">
                                                                        <div
                                                                            role="button"
                                                                            className="d-flex justify-content-between"
                                                                            data-v-8a7c0ea5=""
                                                                        >
                                                                            <div data-v-8a7c0ea5="">
                                                                                <small
                                                                                    className="font-play-refer"
                                                                                    data-v-8a7c0ea5=""
                                                                                >
                                                                                    ยอดเงินในเกม
                                                                                </small>
                                                                                <div
                                                                                    className="text-white d-flex align-items-center"
                                                                                    data-v-8a7c0ea5=""
                                                                                >
                                                                                    <span data-v-8a7c0ea5="">
                                                                                        <img
                                                                                            className="lang-type-bath"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d729c93e-e40d-4c6f-db8b-be26f954d200/public"
                                                                                            alt=""
                                                                                            data-v-8a7c0ea5=""
                                                                                        />
                                                                                    </span>
                                                                                    <b
                                                                                        className="p-2-l-r-box-event"
                                                                                        data-v-8a7c0ea5=""
                                                                                    >
                                                                                        0
                                                                                    </b>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="icon" data-v-8a7c0ea5="">
                                                                    <a
                                                                        role="button"
                                                                        id="icon-refresh-credit"
                                                                        className="mt-2 mb-2"
                                                                        data-v-8a7c0ea5=""
                                                                    >
                                                                        <span data-v-8a7c0ea5="">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={15}
                                                                                height={15}
                                                                                fill="currentColor"
                                                                                className="bi bi-arrow-clockwise text-white-v1"
                                                                                viewBox="0 0 16 16"
                                                                                data-v-8a7c0ea5=""
                                                                            >
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                                                                                    data-v-8a7c0ea5=""
                                                                                />
                                                                                <path
                                                                                    d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
                                                                                    data-v-8a7c0ea5=""
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row animated animatedFadeInUp fadeInUp">
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <a href="/affiliate/overview" >
                                                        <div className="bg-card-item m-1" >
                                                            <div className="p-1 bg-mission-button example">
                                                                <img
                                                                    className="w-100 img-hover-event"
                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/27853edf-e63b-44fe-d4f0-ca075d6e2a00/public"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="p-1">
                                                                <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                    <b className="font-event-size-1 text-ellipsis">
                                                                        แนะนำเพื่อน
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <a href="/loss" >
                                                        <div className="bg-card-item m-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <img
                                                                    className="w-100 img-hover-event"
                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/9dde2261-a3f8-4237-af7b-7566b82b0700/public"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="p-1">
                                                                <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                    <b className="font-event-size-1 text-ellipsis">
                                                                        คืนยอดเสีย
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <a href="/promotion" >
                                                        <div className="bg-card-item m-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <img
                                                                    className="w-100 img-hover-event"
                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/211a5b05-cd76-41f5-cd5b-396825326500/public"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="p-1">
                                                                <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                    <b className="font-event-size-1 text-ellipsis">
                                                                        โปรโมชั่น
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                {/* <div className="col-lg-4 text-center p-1 col-3">
                                                <a href="/promotion" >
                                                    <div className="bg-card-item m-1">
                                                        <div className="p-1 bg-mission-button example">
                                                            <img
                                                                className="w-100 img-hover-event"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57a68c1c-3257-422e-c072-2b2bbf0f8200/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                <b className="font-event-size-1 text-ellipsis">
                                                                    ภารกิจ
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </a>
                                                </div> */}
                                                {/* <div className="col-lg-4 text-center p-1 col-3">
                                                    <div className="bg-card-item m-1">
                                                        <div className="p-1 bg-mission-button example">
                                                            <img
                                                                className="w-100 img-hover-event"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7e052147-f4f9-44b6-25cd-ffc0d45c7600/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                <b className="font-event-size-1 text-ellipsis">
                                                                    กิจกรรมเปิดไพ่
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <div className="bg-card-item m-1">
                                                        <div className="p-1 bg-mission-button example">
                                                            <img
                                                                className="w-100 img-hover-event"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/fb674130-3318-46c3-c482-dd8a0c938700/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                <b className="font-event-size-1 text-ellipsis">
                                                                    แลกของรางวัล
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <div className="bg-card-item m-1">
                                                        <div className="p-1 bg-mission-button example">
                                                            <img
                                                                className="w-100 img-hover-event"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d8563ba2-b284-4bad-4313-dc02549bd200/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                <b className="font-event-size-1 text-ellipsis">
                                                                    คืนยอดเสีย
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <div className="bg-card-item m-1">
                                                        <div className="p-1 bg-mission-button example">
                                                            <img
                                                                className="w-100 img-hover-event"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/caf1df64-acb0-41ad-6cae-4f2f85b89c00/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                <b className="font-event-size-1 text-ellipsis">
                                                                    จัดอันดับ
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>

        );
    }
}
export default App;

import React, { Component } from 'react'; //different
import './App.css';
import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
import moment from 'moment';
import md5 from 'md5';
class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categorylist: [],
      rewardlist: [],
      activePage: 1,
      pagecount: 1,
      products_page_count: 1,
      isActive: false,
      activeTab: 1,
      formData: {}, // Contains login form data
      errors: {}, // Contains login field errors
      formSubmitted: false, // Indicates submit status of login form
      loading: true, // Indicates in progress state of login form
      tblprofile: [],
      userid: null,
      modalx: false,
      modal: false,
      linkaff: "",
      point: 0,
      useradvice: [],
      balance: 0,
      loginstate: null,
      firstname: null,
      lastname: null,
      alert: null,
      modalOpen: false,
      handleClose: false,
      proderedeemlist: [],
      token: window.localStorage.getItem("token"),
      type: "",
      username: "",
      amount: "",
      remark: "",
      mainacc: "",
      rankpic: "1.jpeg",
      rankname: null,
      rankob: null,
      rankpoint: 0,
      name: "",
      password: "",
      left: false,
      pathname: "",
      linelink: ""
    }
  }
  async componentDidMount() {
    await instance.post("/api/v1/mainsetting", {
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        const datas = res.data.message;
        this.setState({
          linelink: datas[1].value,
        });
      }
      else {
        // this.CheckThisGoalError();
      }
    });
    var userAgent = navigator.userAgent.toLowerCase()

    try {
      var token = localStorage.getItem('auth_token');
      //  //////console.log(token);
      if (token != null) {
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await this.rechecklogin(Username, token);
        this.setState({
          userid: decoded.message.playerid,
          username: decoded.message.playerid,
          firstname: decoded.message.firstname,
          lastname: decoded.message.lastname,
          loginstate: true,
        });
      }
      else {
        this.setState({
          loginstate: false,
        });
      }
    }
    catch (error) {
      //////console.log();
    }

  }
  logout = (e) => {

    // window.location.href = '/logout';

  }
  async componentDidMount() {
    await instance.post("/api/v1/mainsetting", {
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        const datas = res.data.message;
        this.setState({
          linelink: datas[1].value,
        });
      }
      else {
        // this.CheckThisGoalError();
      }
    });
    var userAgent = navigator.userAgent.toLowerCase()
    // if (userAgent.indexOf('safari') != -1) {
    //     if (userAgent.indexOf('chrome') > -1) {
    //     } else {
    //         await Swal.fire({
    //             title: 'Warning!',
    //             text: 'แนะนำให้ใช้งานบน Google Chorme เพื่อประสิทธิภาพที่ดีกว่า',
    //             icon: 'warning',
    //             confirmButtonText: 'ตกลง'
    //         })
    //     }
    // }

    try {
      var token = localStorage.getItem('auth_token');
      //  //////console.log(token);
      if (token != null) {
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await this.rechecklogin(Username, token);
        this.setState({
          userid: decoded.message.playerid,
          username: decoded.message.playerid,
          firstname: decoded.message.firstname,
          lastname: decoded.message.lastname,
          loginstate: true,
        });
      }
      else {
        this.setState({
          loginstate: false,
        });
      }
    }
    catch (error) {
      //////console.log();
    }

  }
  openAccout(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].className += " -open";
    }
  }
  async checkuseradvice() {
    instance.post("/api/v1/checkuseradvice", {
      User_id: this.state.username,
      page: 1,
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.message.useradvicelist);
        this.setState({
          useradvice: res.data.message.useradvicelist
        });
      }
      else {
        // console.log(res.data);
        // this.CheckThisGoalError();
      }
    });
  }

  cancelredeempromotion = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการยกเลิกการใช้โปรใช่ หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/usercancelpromotion", {
            User_id: Username,
            System: Systems,
            no: index.no,
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              this.checkpro(null, 999);
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
            }
          });

        }
      })
    }

  }

  openAccoutcan(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].className = "x-menu-account-list-sidebar";
    }
  }

  async rechecklogin(Username, token) {

    try {
      // console.log(4);
      const search = window.location.href;
      const paramx = new URLSearchParams(search);
      // const space = search.split("/")
      const space = window.location.host
      var userhash = md5(Username);
      //  const linkaff = "https://pgline88.com/?register=" + userhash;
      const linkaff = "https://" + space + "/register?reg=" + userhash;
      instance.post("/api/v1/checkpoint", {
        userid: Username,
        System: Systems,
        Function: 'rechecklogin-navbar.js'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        //  console.log(res.data);
        if (res.data.status === 200) {
          localStorage.setItem('auth_token', res.data.token)
          this.setState({
            rankpic: res.data.tblrank.image,
            rankname: res.data.tblrank.name,
            linkaff: linkaff,
            rankob: res.data.tblrank,
            rankpoint: res.data.rankpoint,
            accnumber: res.data.message.accnumber,
            userid: res.data.message.member_id,
            tblprofile: res.data.message,
            username: res.data.message.playerid,
            fname: res.data.message.firstname,
            point: res.data.message.Point,
            balance: res.data.databalance,
            loginstate: true,
          });
        }
        else if (res.data.status === 401) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }

      }).catch(async (err) => {
        console.log(err)
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }
      });
    }
    catch (ex) {
      //////console.log(ex);
    }

  }
  checkpro = async (e, proid) => {
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    await instance.post("/api/v1/checkpromotion", {
      userid: Username,
      System: Systems,
      Proid: proid,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      if (res.data.status === 200) {
        //console.log(res.data)
        // console.log(res.data.message.tblpromotionredeems)
        if (res.data.type === 0) {
          this.setState({
            prodetail: res.data.message.tblpromotions,
          });
        }
        else if (res.data.type === 1) {
          this.setState({
            proderedeemlist: res.data.message.tblpromotionredeems,
          });

        }

      }
      else if (res.data.status === 201) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }


  validateLogout = (e) => {
    localStorage.removeItem('auth_token', '')
    this.setState({
      userid: null,
      username: null,
      password: null,
      firstname: null,
      lastname: null,
      point: null,
      loginstate: false,
    });
  }
  async recheccredit(evt) {

    evt.currentTarget.className += " fa-spin";
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    //  this.rechecklogin(Username, token);
    this.setState({
      userid: decoded.message.playerid,
      username: decoded.message.playerid,
      firstname: decoded.message.firstname,
      lastname: decoded.message.lastname,
      loginstate: true,
    });
    try {
      //  console.log(5);
      await instance.post("/api/v1/checkpoint", {
        userid: Username,
        System: Systems,
        Function: 'recheccredit-navbar.js'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        //console.log(res.data);
        if (res.data.status === 200) {
          localStorage.setItem('auth_token', res.data.token)
          this.setState({
            accnumber: res.data.message.accnumber,
            userid: res.data.message.member_id,
            username: res.data.message.playerid,
            fname: res.data.message.firstname,
            point: res.data.message.Point,
            balance: res.data.databalance,
            loginstate: true,
          });
          var i, tablinks;
          tablinks = document.getElementsByClassName("-btn-balance");
          for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" fa-spin", "");
          }
        }
        else if (res.data.status === 401) {
          localStorage.clear();
          //   console.log(res.data);
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }

      }).catch(async (err) => {
        //    console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }
      });
    }
    catch (ex) {
      //////console.log(ex);
    }
  }

  logout = (e) => {
    // e.preventDefault();
    let errors = this.validateLogout();
    if (errors === true) {
      // alert("You are successfully signed in...");
      window.location.reload()
    } else {

      this.setState({
        errors: errors,
        formSubmitted: true
      });
      window.location.reload()
    }
  }

  render() {
    const pathname = this.state.pathname
    const { loginstate, userid, username, point, firstname, lastname, balance, proderedeemlist, linkaff, tblprofile } = this.state
    return (
      <>

        <div className="container align-items-center h-100 position-relative">
          <div id="headerBrand">
            <a
              onClick={(e) => {
                this.setState({
                  modalx: true
                });
              }}
              className="btn navbar-brand"
              style={{
                cursor: "pointer",
                width: 20,
                color: "rgba(95, 117, 223, 0) !important"
              }}
            >
              <div
                role="button"
                className="menu_icon"
                style={{ top: "-25px", left: "-15px" }}
              >
                <span className="one" />
                <span className="two" />
                <span className="three" />
              </div>
            </a>
            <div className="-branding-inner-wrapper">
              <a
                onClick={(e) => {
                  window.location.href = '/'
                }}
                className="navbar-brand" role="button">
                <img
                  src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                  className="w-logo-web-v22"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div id="headerContent" style={{ display: loginstate ? "none" : "" }}>
            <ul className="nav -menu-wrapper -logged">
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
            </ul>
            <div>
              <div className="navbar-nav">
                <div className="x-logged"  >
                  <div className="-profile-container">
                    <div>
                      <div className="d-flex">

                        <div className="flex-fill" style={{ marginRight: "5px" }}>
                          <a
                            type="button"
                            href="/register"
                            className="register-auth p-2 pl-2-1 v-register"
                          >
                            สมัคร
                          </a>
                        </div>
                        <div className="flex-fill">
                          <a
                            type="button"
                            href="/signin"
                            className="login-auth p-2 pl-2-1 v-login"
                          >
                            เข้าสู่ระบบ
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/**/}
            </div>
          </div>
          <div id="headerContent" style={{ display: loginstate ? "" : "none" }}>
            <ul className="nav -menu-wrapper -logged">
              <li className="nav-item">
                <a
                  href="/refer/overview"
                  className="nav-link -promotion" target="_self">
                  <div className="-img-wrapper">
                    <img
                      src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/6934af10-4429-484f-5bbc-b9d6ab018800/public"
                      className="-icon"
                      alt="Menu icon promotion"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="-text">แนะนำเพื่อน</div>
                </a>
              </li>
              <li className="nav-item">
                <a href="/rebate" className="nav-link -promotion" target="_self">
                  <div className="-img-wrapper">
                    <img
                      src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/b5edf8be-4cb4-4b80-9f8f-05ee0557a500/public"
                      className="-icon"
                      alt="Menu icon promotion"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="-text">คืนค่ายอดเสีย</div>
                </a>
              </li>
              <li className="nav-item">
                <a href="/event" className="nav-link -promotion" target="_self">
                  <div className="-img-wrapper">
                    <img
                      src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/14b1a3cf-e250-4d31-7541-cd39f837d300/public"
                      className="-icon"
                      alt="Menu icon promotion"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="-text">กิจกรรม</div>
                </a>
              </li>
              <li className="nav-item">
                <a href="/promotion" className="nav-link -promotion" target="_self">
                  <div className="-img-wrapper">
                    <img
                      src="https://play.168wowza.com/assets/ic-header-menu-promotion.d5c30a79.png"
                      className="-icon"
                      alt="Menu icon promotion"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="-text">โปรโมชั่น</div>
                </a>
              </li>
            </ul>
            <div>
              {/**/}
              <div className="navbar-nav">
                <div className="js-ez-logged-sidebar -btn-mobile-wrapper">
                  <div className="x-profile-image">
                    <a href="/profile">
                      <img

                        role="button"
                        className="img-fluid -profile-image"
                        src={"/build/rank/" + this.state.rankpic}
                        alt="customer image"
                        style={{ width: 43 }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>





        <div
          className="base-modal-overlay x-modal modal -v2 x-theme-switcher-v2 show animated animatedFadeInUp fadeInUp"
          data-v-204943c0=""
          style={{ display: this.state.modalx ? "" : "none" }}
        >
          <div
            className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered w-100 m-0"
            data-v-204943c0=""
            style={{ height: "auto" }}
          >
            <div className="modal-content -modal-content text-white" data-v-204943c0="">
              <a
                onClick={(e) => {
                  this.setState({
                    modalx: false
                  });
                }}
                role="button"
                className="close-model-head mt-4 mb-4"
                data-v-204943c0=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  height={30}
                  fill="currentColor"
                  className="bi bi-x-circle-fill"
                  viewBox="0 0 16 16"
                  data-v-204943c0=""
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                    data-v-204943c0=""
                  />
                </svg>
              </a>
              {/**/}
              <div className="pt-3 pb-4 x-theme-switcher-v2 text-center mt-3" data-v-204943c0="" style={{ display: loginstate ? "" : "none" }}  >
                <div className="-inner-header-section" data-v-204943c0="">
                  <img
                    src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                    alt=""
                    data-v-204943c0=""
                    style={{ width: 260 }}
                  />
                </div>
                <div className="row -inner-center-body-section m-0" data-v-204943c0="" >
                  <div className="col-6 -wrapper-box" data-v-204943c0="">
                    <a
                      role="button"
                      href='/profile'
                      className="btn -btn-item -event-button -menu-center -horizontal"
                      data-v-204943c0=""
                    >
                      <picture data-v-204943c0="">
                        <source
                          type="image/webp"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e8452a1b-5ada-4f64-773f-5d87a36b9800/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e8452a1b-5ada-4f64-773f-5d87a36b9800/public"
                          data-v-204943c0=""
                        />
                        <source
                          type="image/png?v=1"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e8452a1b-5ada-4f64-773f-5d87a36b9800/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e8452a1b-5ada-4f64-773f-5d87a36b9800/public"
                          data-v-204943c0=""
                        />
                        <img
                          className="img-fluid -icon-image ls-is-cached lazyloaded"
                          data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e8452a1b-5ada-4f64-773f-5d87a36b9800/public"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e8452a1b-5ada-4f64-773f-5d87a36b9800/public"
                          data-v-204943c0=""
                        />
                      </picture>
                      <div className="-typo-wrapper" data-v-204943c0="">
                        <div className="-typo -is-logged" data-v-204943c0="">
                          <span data-v-204943c0="">ข้อมูลบัญชี</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-6 -wrapper-box" data-v-204943c0="">
                    <a
                      role="button"
                      href='/statement'
                      className="btn -btn-item -event-button -menu-center -horizontal"
                      data-v-204943c0=""
                    >
                      <picture data-v-204943c0="">
                        <source
                          type="image/webp"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/800101be-6e7a-4619-559c-0264c03bdb00/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/800101be-6e7a-4619-559c-0264c03bdb00/public"
                          data-v-204943c0=""
                        />
                        <source
                          type="image/png?v=1"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/800101be-6e7a-4619-559c-0264c03bdb00/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/800101be-6e7a-4619-559c-0264c03bdb00/public"
                          data-v-204943c0=""
                        />
                        <img
                          className="img-fluid -icon-image ls-is-cached lazyloaded"
                          data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/800101be-6e7a-4619-559c-0264c03bdb00/public"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/800101be-6e7a-4619-559c-0264c03bdb00/public"
                          data-v-204943c0=""
                        />
                      </picture>
                      <div className="-typo-wrapper" data-v-204943c0="">
                        <div className="-typo -is-logged" data-v-204943c0="">
                          <span data-v-204943c0="">ประวัติ</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-6 -wrapper-box" data-v-204943c0="">
                    <a
                      href='/refer/overview'
                      role="button"
                      className="btn -btn-item -event-button -menu-center -horizontal"
                      data-v-204943c0=""
                    >
                      <picture data-v-204943c0="">
                        <source
                          type="image/webp"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d03ff0f5-f6cb-440e-391f-d78cd8846000/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d03ff0f5-f6cb-440e-391f-d78cd8846000/public"
                          data-v-204943c0=""
                        />
                        <source
                          type="image/png?v=1"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d03ff0f5-f6cb-440e-391f-d78cd8846000/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d03ff0f5-f6cb-440e-391f-d78cd8846000/public"
                          data-v-204943c0=""
                        />
                        <img
                          className="img-fluid -icon-image ls-is-cached lazyloaded"
                          data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d03ff0f5-f6cb-440e-391f-d78cd8846000/public"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d03ff0f5-f6cb-440e-391f-d78cd8846000/public"
                          data-v-204943c0=""
                        />
                      </picture>
                      <div className="-typo-wrapper" data-v-204943c0="">
                        <div className="-typo -is-logged" data-v-204943c0="">
                          <span data-v-204943c0="">เเนะนำเพื่อน</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-6 -wrapper-box" data-v-204943c0="">
                    <a
                      href='/promotion'
                      role="button"
                      className="btn -btn-item -event-button -menu-center -horizontal"
                      data-v-204943c0=""
                    >
                      <picture data-v-204943c0="">
                        <source
                          type="image/webp"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5cfc57b5-d0dc-4a6b-3823-bd521ccb6b00/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5cfc57b5-d0dc-4a6b-3823-bd521ccb6b00/public"
                          data-v-204943c0=""
                        />
                        <source
                          type="image/png?v=1"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5cfc57b5-d0dc-4a6b-3823-bd521ccb6b00/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5cfc57b5-d0dc-4a6b-3823-bd521ccb6b00/public"
                          data-v-204943c0=""
                        />
                        <img
                          className="img-fluid -icon-image ls-is-cached lazyloaded"
                          data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5cfc57b5-d0dc-4a6b-3823-bd521ccb6b00/public"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5cfc57b5-d0dc-4a6b-3823-bd521ccb6b00/public"
                          data-v-204943c0=""
                        />
                      </picture>
                      <div className="-typo-wrapper" data-v-204943c0="">
                        <div className="-typo -is-logged" data-v-204943c0="">
                          <span data-v-204943c0="">โปรโมชั่น</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-6 -wrapper-box" data-v-204943c0="">
                    <a
                      href='/event'
                      role="button"
                      className="btn -btn-item -event-button -menu-center -horizontal"
                      data-v-204943c0=""
                    >
                      <picture data-v-204943c0="">
                        <source
                          type="image/webp"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2edbcef7-3174-404b-8ad8-258048dea000/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2edbcef7-3174-404b-8ad8-258048dea000/public"
                          data-v-204943c0=""
                        />
                        <source
                          type="image/png?v=1"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2edbcef7-3174-404b-8ad8-258048dea000/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2edbcef7-3174-404b-8ad8-258048dea000/public"
                          data-v-204943c0=""
                        />
                        <img
                          className="img-fluid -icon-image ls-is-cached lazyloaded"
                          data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2edbcef7-3174-404b-8ad8-258048dea000/public"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2edbcef7-3174-404b-8ad8-258048dea000/public"
                          data-v-204943c0=""
                        />
                      </picture>
                      <div className="-typo-wrapper" data-v-204943c0="">
                        <div className="-typo -is-logged" data-v-204943c0="">
                          <span data-v-204943c0="">เเลกของรางวัล</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-6 -wrapper-box" data-v-204943c0="">
                    <a
                      href='/rebate'
                      role="button"
                      className="btn -btn-item -event-button -menu-center -horizontal"
                      data-v-204943c0=""
                    >
                      <picture data-v-204943c0="">
                        <source
                          type="image/webp"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3fe359b5-1230-42ee-cd78-fdd5a68ea800/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3fe359b5-1230-42ee-cd78-fdd5a68ea800/public"
                          data-v-204943c0=""
                        />
                        <source
                          type="image/png?v=1"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3fe359b5-1230-42ee-cd78-fdd5a68ea800/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3fe359b5-1230-42ee-cd78-fdd5a68ea800/public"
                          data-v-204943c0=""
                        />
                        <img
                          className="img-fluid -icon-image ls-is-cached lazyloaded"
                          data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3fe359b5-1230-42ee-cd78-fdd5a68ea800/public"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3fe359b5-1230-42ee-cd78-fdd5a68ea800/public"
                          data-v-204943c0=""
                        />
                      </picture>
                      <div className="-typo-wrapper" data-v-204943c0="">
                        <div className="-typo -is-logged" data-v-204943c0="">
                          <span data-v-204943c0="">คืนค่าคอมมิชชั่น</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-6 -wrapper-box" data-v-204943c0="">
                    <a
                      href='/event'
                      role="button"
                      className="btn -btn-item -event-button -menu-center -horizontal"
                      data-v-204943c0=""
                    >
                      <picture data-v-204943c0="">
                        <source
                          type="image/webp"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/19012ba0-2564-42d2-d94a-ee8b57a0bc00/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/19012ba0-2564-42d2-d94a-ee8b57a0bc00/public"
                          data-v-204943c0=""
                        />
                        <source
                          type="image/png?v=1"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/19012ba0-2564-42d2-d94a-ee8b57a0bc00/public"
                          srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/19012ba0-2564-42d2-d94a-ee8b57a0bc00/public"
                          data-v-204943c0=""
                        />
                        <img
                          className="img-fluid -icon-image ls-is-cached lazyloaded"
                          data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/19012ba0-2564-42d2-d94a-ee8b57a0bc00/public"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/19012ba0-2564-42d2-d94a-ee8b57a0bc00/public"
                          data-v-204943c0=""
                        />
                      </picture>
                      <div className="-typo-wrapper" data-v-204943c0="">
                        <div className="-typo -is-logged" data-v-204943c0="">
                          <span data-v-204943c0="">โบนัสเพิ่ม</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-6 -wrapper-box" data-v-204943c0="">
                    <a
                      href="/logout"
                      role="button"
                      className="btn -btn-item -event-button -menu-center -horizontal"
                      data-v-204943c0=""
                    >
                      <picture data-v-204943c0="">
                        <source
                          type="image/webp"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5b7096f3-1eb4-469f-0cf9-6275c441e500/public"
                          srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5b7096f3-1eb4-469f-0cf9-6275c441e500/public
                "
                          data-v-204943c0=""
                        />
                        <source
                          type="image/png?v=1"
                          data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5b7096f3-1eb4-469f-0cf9-6275c441e500/public"
                          srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5b7096f3-1eb4-469f-0cf9-6275c441e500/public
                "
                          data-v-204943c0=""
                        />
                        <img
                          className="img-fluid -icon-image ls-is-cached lazyloaded"
                          data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5b7096f3-1eb4-469f-0cf9-6275c441e500/public"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5b7096f3-1eb4-469f-0cf9-6275c441e500/public"
                          data-v-204943c0=""
                        />
                      </picture>
                      <div className="-typo-wrapper" data-v-204943c0="">
                        <div className="-typo -is-logged" data-v-204943c0="">
                          ออกจากระบบ
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-12 -wrapper-box" data-v-576a2e8e="">
                    <div
                      className="btn -btn-item -line-button -menu-center -horizontal"
                      data-v-576a2e8e=""
                    >
                      <div
                        className="mt-1 mb-1 w-100"
                        data-v-576a2e8e=""
                        style={{ textAlign: "center" }}
                      >
                        <h3 className="title-footer-wrap pt-2 text-white">
                          <b>Language / ภาษา / ဘာသာစကား </b>
                        </h3>
                        <ul className="logo-list-footer -casino">
                          <li role="button">
                            <img
                              className="lang-img active-lang"
                              src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                              alt=""
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
           
                <br data-v-204943c0="" />
                <br data-v-204943c0="" />
                <br data-v-204943c0="" />
                <br data-v-204943c0="" />
                <br data-v-204943c0="" />
                <br data-v-204943c0="" />
                <br data-v-204943c0="" />
              </div>
              <div className="pt-3 pb-4 x-theme-switcher-v2 text-center mt-3" style={{ display: loginstate ? "none" : "" }} data-v-204943c0="" >
                  <div className="-inner-header-section" data-v-204943c0="">
                    <img
                      src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                      alt=""
                      data-v-204943c0=""
                      style={{ width: 260 }}
                    />
                  </div>
                  <div className="row -inner-top-body-section" data-v-204943c0="">
                    <div
                      className="col-6 -wrapper-box"
                      data-v-204943c0=""
                      style={{ paddingLeft: 0 }}
                    >
                      <a
                        role="button"
                        href="/signin"
                        className="btn -btn-item x-transaction-button-v2 -deposit -top-btn -horizontal"
                  
                        data-v-204943c0=""
                      >
                        <div data-v-204943c0="">
                          <img
                            alt="venis bet รูปไอคอนฝากเงิน"
                            className="img-fluid -icon-image"
                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a138dd7e-8f3a-46f9-1711-4ee9f4ac9d00/public"
                            data-v-204943c0=""
                            style={{ width: 60 }}
                          />
                        </div>
                        <div className="-typo-wrapper" data-v-204943c0="">
                          <div className="-title text-xl" data-v-204943c0="">
                            สมัครสมาชิก
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      className="col-6 -wrapper-box"
                      data-v-204943c0=""
                      style={{ paddingRight: 0 }}
                    >
                      <a
                        role="button"
                        href="/register"
                        className="btn -btn-item x-transaction-button-v2 -withdraw -top-btn -horizontal"
           
                        data-v-204943c0=""
                      >
                        <img
                          alt=" รูปไอคอนถอนเงิน"
                          className="img-fluid -icon-image"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/99f8ec28-7c9b-42a6-a244-c647020f0700/public"
                          data-v-204943c0=""
                          style={{ width: 60 }}
                        />
                        <div className="-typo-wrapper" data-v-204943c0="">
                          <div className="-title" data-v-204943c0="">
                            เข้าสู่ระบบ
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="row justify-content-center -inner-center-body-section m-0"
                    data-v-204943c0=""
                  >
                    <div
                      className="col-10 -wrapper-box justify-content-center"
                      data-v-204943c0=""
                    >
                      <a
                         href="/promotion"
                        role="button"
                        className="btn -btn-item -promotion-button -menu-center -horizontal icon-text-wrapper"
                        data-v-204943c0=""
                      >
                        <picture data-v-204943c0="">
                          <source
                            type="image/webp"
                            data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/afcf2643-3321-4924-d4de-617d1cd9cb00/public"
                            srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/afcf2643-3321-4924-d4de-617d1cd9cb00/public
                "
                            data-v-204943c0=""
                          />
                          <source
                            type="image/png?v=1"
                            data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/afcf2643-3321-4924-d4de-617d1cd9cb00/public"
                            srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/afcf2643-3321-4924-d4de-617d1cd9cb00/public
                "
                            data-v-204943c0=""
                          />
                          <img
                            alt="รูปไอคอนโปรโมชั่น"
                            className="img-fluid -icon-image ls-is-cached lazyloaded"
                            data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/afcf2643-3321-4924-d4de-617d1cd9cb00/public"
                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/afcf2643-3321-4924-d4de-617d1cd9cb00/public"
                            data-v-204943c0=""
                          />
                        </picture>
                        <div className="-typo-wrapper" data-v-204943c0="">
                          <div className="-typo -is-logged" data-v-204943c0="">
                            โปรโมชั่น
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-10 -wrapper-box" data-v-204943c0="">
                      <a
                             href={this.state.linelink}
                        role="button"
                        className="btn -btn-item -line-button -menu-center -horizontal"
                        rel="noopener nofollow"
                        data-v-204943c0=""
                        style={{
                          backgroundImage: "unset",
                          backgroundPosition: "unset",
                          backgroundSize: "unset",
                          backgroundRepeat: "unset",
                          backgroundAttachment: "unset",
                          backgroundOrigin: "unset",
                          backgroundClip: "unset",
                          backgroundColor: "rgb(1, 199, 85) !important"
                        }}
                      >
                        <picture data-v-204943c0="">
                          <source
                            type="image/webp"
                            data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/cba56b52-aab0-4da6-bd79-c501e8302200/public"
                            srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/cba56b52-aab0-4da6-bd79-c501e8302200/public
                "
                            data-v-204943c0=""
                          />
                          <source
                            type="image/png?v=1"
                            data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/cba56b52-aab0-4da6-bd79-c501e8302200/public"
                            srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/cba56b52-aab0-4da6-bd79-c501e8302200/public
                "
                            data-v-204943c0=""
                          />
                          <img
                            alt=" รูปไอคอนดูหนัง"
                            className="img-fluid -icon-image ls-is-cached lazyloaded"
                            data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/cba56b52-aab0-4da6-bd79-c501e8302200/public"
                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/cba56b52-aab0-4da6-bd79-c501e8302200/public"
                            data-v-204943c0=""
                          />
                        </picture>
                        <div className="-typo-wrapper" data-v-204943c0="">
                          <div className="-typo -is-logged" data-v-204943c0="">
                            {" "}
                            Line{" "}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-10 -wrapper-box" data-v-204943c0="">
                      <div
                        className="btn -btn-item -line-button -menu-center -horizontal"
                        data-v-204943c0=""
                        style={{ backgroundColor: "unset !important" }}
                      >
                        <div
                          className="mt-1 mb-1 w-100"
                          data-v-204943c0=""
                          style={{ textAlign: "center" }}
                        >
                          <h3 className="title-footer-wrap pt-2">
                            <b>Language / ภาษา / ဘာသာစကား </b>
                          </h3>
                          <ul className="logo-list-footer -casino">
                            <li role="button">
                              <img
                                className="lang-img active-lang"
                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                                alt=""
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                  <br data-v-204943c0="" />
                </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
export default Main;


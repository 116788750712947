import React, { Component } from 'react'; //different
//import './style.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import Banner from './banner';
import MoblieMenu from "./mobilemenu";
import TextSlider from './textslide';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2'
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: props.props.username,
            products_page_count: 1,
            loginstate: props.props.loginstate,
            firstname: null,
            lastname: null,
            alert: null,
        }
        this.handleClick = this.handleClick.bind(this);

    }
    handlePageChange(pageNumber) {
        //////console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });

        instance
            .get("/api/v1/rewardlist", {
                params: {
                    Cat_ID: 0,
                    page: pageNumber
                }
            })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
    }

    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }


    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            this.setState({
                user_id: decoded.message.playerid,
                playerid: decoded.message.member_id,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    redeem = (e, partner) => {
        var userAgent = navigator.userAgent
        const search = window.location.href;
        const params = new URLSearchParams(search);
        const pathname = window.location.pathname.split("/")
        console.log(pathname);
        instance.post("/api/v1/tabgamelist", {
            state: "999",
            System: Systems,
            partner: partner,
            game: pathname[1].replace("#", ""),
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.message.length == 1) {
                        instance.post("/api/v1/tablauncher", {
                            gameId: "none",
                            partner: partner,
                            System: Systems,
                            playerUsername: this.props.props.username,
                            isMobile: isMobile,
                            demo: false
                        })
                            .then((res) => {
                                if (res.data.status === 200) {
                                    console.log(res.data.message);
                                    var test = res.data.message.launcher
                                    if (test !== undefined)
                                        if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                            window.location.href = test.mobile;
                                        }
                                        else {
                                            if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                            else window.open(test.mobile);
                                        }
                                    else if (test === undefined) {
                                        Swal.fire({
                                            title: 'error!',
                                            text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                            icon: 'error',
                                            confirmButtonText: 'ตกลง'
                                        })
                                    }
                                }
                                else {
                                    //  this.CheckThisGoalError();
                                }
                            });
                    }
                    else {
                        window.location.href = "/listgameall/slot/" + partner;
                    }
                }
            });
    }
    async componentDidMount() {
        var token = localStorage.getItem('auth_token');
        const search = window.location.href;
        const params = new URLSearchParams(search);
        //////console.log(params);
        this.checklogin();
        await instance.post("/api/v1/tabgamelist", {
            state: "666",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });

    }

    render() {
        return (
            <>
                <div>
                    <main className="container p-0">
                        <div className="p-1 text-left container-xl text-white mt-1 my5 animated animatedFadeInUp fadeInUp">
                            {/**/}
                            <div className="clearfix">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4 className="ps-2 mb-0 f-22 text-title">
                                            <b>สล็อต</b>
                                        </h4>
                                    </div>
                                    <div>
                                        <img role="button" alt="" className="w-icon-cr-bl-record-all" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-1 pb-3 text-white w-scroll-height">
                            <div className="x-category-provider js-game-scroll-container js-game-container animated animatedFadeInUp fadeInUp">
                                <div className="-games-list-container container">
                                    <nav className="nav-menu" id="navbarProvider">
                                        <ul className="nav nav-pills row animated animatedFadeInUp fadeInUp">
                                            {this.state.categorylist.map((listitem, i) => (
                                                <li className="col-4 col-lg-4 col-md-4 nav-item card-width -random-container -game-casino-macro-container p-1 pb-0 mb-2">
                                                    <a key={i} id={listitem.gameid}
                                                        href={() => false}
                                                        onClick={(e) => this.redeem(e, listitem.partner)}

                                                    >
                                                        <div
                                                            className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable example"
                                                            data-status="-cannot-entry -untestable"
                                                        >
                                                            <div className="-inner-wrapper card-width-banner">
                                                                <div>
                                                                    {/**/}
                                                                    {/**/}
                                                                    {/**/}
                                                                </div>
                                                                <picture>
                                                                    <img
                                                                        role="button"
                                                                        className="w-100 hover-slot hoverTextCss"
                                                                        lazy="loaded"
                                                                        data-src={listitem.imagesec}
                                                                        src={listitem.imagesec}
                                                                    />
                                                                </picture>
                                                                <div className="-overlay d-none d-lg-block">
                                                                    <div className="-overlay-inner">
                                                                        <div className="-wrapper-container">
                                                                            <button href={() => false} className="-btn -btn-play js-account-approve-aware"  >
                                                                                <i className="fas fa-play" />
                                                                                <span className="-text-btn">เข้าเล่น</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-title mt-1 mb-0 text-center text-color-name-game">
                                                                {listitem.description}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>

                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </main >
                </div >

            </>
        );
    }
}
export default App;
